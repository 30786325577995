export const readTask = async (task_id, taskCache) => {

    var base_url = getUrl("/api/tasks/");
    var results = await logFetchAJAX(`${base_url}${task_id}?recurse=true`);
    var alltasks = results.descendants || {};
    for (const taskid in alltasks) {
      taskCache[taskid] = alltasks[taskid]
    }

    for (const taskid in alltasks) {
      hydrateTask(alltasks[taskid], taskCache)
    }

    hydrateTask(results["task"], taskCache)
    // remove sub tasks not in task all tasks
    for (var i = (results["task"]["sub_tasks"] || []).length - 1;i >= 0;i--) {
      const st = results["task"]["sub_tasks"][i]
      const tid = st["taskid"]
      if (!tid || !alltasks[tid]) {
        results["task"]["sub_tasks"].splice(i, 1)
      }
    }
    return results["task"]
}
